import React, {Component} from 'react';
import './Certificates.css';
import CertificateItem from './CertificateItem';
import CertificatesContainer from './CertificatesContainer';
import badgeAzureFundamentals from './Badges/microsoft-certified-azure-fundamentals.png';
import badgeAzureDeveloper from './Badges/microsoft-certified-azure-developer-associate.png';
import badgeSAPAlumni from './Badges/sap-alumni-award.png';
import badgeIBMCloudEssentials from './Badges/ibm-cloud-essentials.png';

export default class Certificates extends Component {
  render() {
    return (
      <CertificatesContainer>
        <CertificateItem title="Microsoft Certified: Azure Developer Associate" subtitle="Exam AZ-204" badge={badgeAzureDeveloper}
          link="https://learn.microsoft.com/api/credentials/share/en-gb/FelixFranz-2224/B6187C1FB54AA5B8?sharingId=21F66DD924E05025">
          Earning Azure Developer Associate certification validates the skills and knowledge to design, build, test, and maintain cloud applications and services on Microsoft Azure. Candidates participate in all phases of cloud development from requirements definition and design, to development, deployment, and maintenance.
        </CertificateItem>
        <CertificateItem title="Microsoft Certified: Azure Fundamentals" subtitle="Exam AZ-900" badge={badgeAzureFundamentals}
          link="https://learn.microsoft.com/api/credentials/share/en-gb/FelixFranz-2224/6AE925BE2E49E4B?sharingId=21F66DD924E05025">
          Earning Azure Fundamentals certification have demonstrated foundational level knowledge of cloud services and how those services are provided with Microsoft Azure.
        </CertificateItem>
        <CertificateItem title="SAP Alumni Award" subtitle="Award" badge={badgeSAPAlumni}
          link="https://www.credly.com/badges/0d5649d3-b0ab-4ce0-9ef4-36c9ae1c33e0">
          The owner of this badge is a validated member of SAP's trust-based network of previous employees and has contributed to SAPs success over the years.
          As a valued member of the SAP alumni network she / he will be able to excel as a business enabler for SAPs whole ecosystem - and the entire IT industry.
        </CertificateItem>
        <CertificateItem title="IBM Cloud Essentials" subtitle="Exam IB Cloud Essentials" badge={badgeIBMCloudEssentials}
          link="https://www.credly.com/badges/5eecbe0d-ac83-427d-8ccb-9b441bf9802d">
          This badge holder has a understanding of the essentials of IBM Cloud.
          Through an online course they have learned about the IBM Cloud platform and gained hands-on experience using the Cloud Foundry command-line interface and the IBM Eclipse tools for IBM Cloud.
          The badge earner has used these tools to deploy and modify basic applications using a managed service in IBM Cloud.
        </CertificateItem>
      </CertificatesContainer>
    );
  }
}
